import { ChartConfiguration, ChartTypeRegistry } from "chart.js";

interface XYZData {
  x: number[];
  y: number[];
  z: number[];
}

const smoothAxisData = (d: number[]): number[] => {
  if (d.length === 0) {
    return d;
  }
  const newData: number[] = [];
  let prevValue = d[0];
  d.forEach(v => {
    const newValue = v * 0.25 + prevValue * 0.75;
    newData.push(newValue);
    prevValue = newValue;
  });
  return newData;
};

interface Pos {
  x: number;
  y: number;
}

const processDimensionData = (data: number[]) => {
  const formatToChartPos = (y: number, idx: number) => ({ x: idx, y });
  return smoothAxisData(data).map(formatToChartPos);
};

export const getRecordingGraphConfig = (
  { x: rawX, y: rawY, z: rawZ }: XYZData,
  colors: { x: string; y: string; z: string }
): ChartConfiguration<keyof ChartTypeRegistry, Pos[], string> => {
  const x = processDimensionData(rawX);
  const y = processDimensionData(rawY);
  const z = processDimensionData(rawZ);
  const common = { borderWidth: 1, pointRadius: 0, pointHoverRadius: 0 };
  return {
    type: "line",
    data: {
      datasets: [
        {
          ...common,
          label: "x",
          borderColor: colors.x,
          data: x
        },
        {
          ...common,
          label: "y",
          borderColor: colors.y,
          data: y
        },
        {
          ...common,
          label: "z",
          borderColor: colors.z,
          data: z
        }
      ]
    },
    options: {
      animation: false,
      responsive: false,
      maintainAspectRatio: false,

      interaction: {
        intersect: false,
        mode: "index"
      },
      scales: {
        x: {
          type: "linear",
          min: 0,
          max: rawX.length,
          grid: {
            drawTicks: false,
            display: false
          },
          ticks: {
            display: false //this will remove only the label
          },
          display: false
        },
        y: {
          type: "linear",
          min: -2.5,
          max: 2.5,
          grid: {
            drawTicks: false,
            display: false
          },
          ticks: {
            display: false //this will remove only the label
          },
          display: false
        }
      },
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false
        }
      }
    }
  };
};
