import React from "react";
import GetText from "../../i18n/GetText";
import { SanityMake } from "../../model/makes";
import FeaturedMakes from "../FeaturedMakes/FeaturedMakes";
import Text from "../Text/Text";

const RelatedMakeContent = ({
  className,
  content
}: RelatedMakeContentProps) => {
  return (
    <div className={className}>
      <Text variant="h3">
        <GetText id="you-may-also-like" />
      </Text>
      <FeaturedMakes node={{ items: content }} />
    </div>
  );
};

export default RelatedMakeContent;

interface RelatedMakeContentProps {
  className?: string;
  content: SanityMake[];
}
