import {
  Chart,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  registerables
} from "chart.js";
import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import Text from "../Text/Text";
import styles from "./DataEmbed.module.scss";
import { Icon, icons } from "./icons";
import { getRecordingGraphConfig } from "./recording-graph";
import { MicrobitCode, SanityCodeDocument } from "../../model/common";
import { getGestureData } from "../../utils/create-ai-project";
import { Col, Row } from "../Grid";
import OpenInCreateAIButton from "../OpenInCreateAIButton/OpenInCreateAIButton";
import { codeDownloadUrl } from "../../utils/downloads";
import { SanityLesson, SanityUnitOfWork } from "../../model/lessons";
import { SanityMake } from "../../model/makes";
import AnchorButton from "../AnchorButton/AnchorButton";
import GetText from "../../i18n/GetText";

interface RecordingData {
  data: {
    x: number[];
    y: number[];
    z: number[];
  };
}

export interface GestureData {
  name: string;
  icon: Icon;
  recordings: RecordingData[];
}

interface DataEmbedProps {
  code: MicrobitCode;
  context: SanityCodeDocument;
  unit: SanityUnitOfWork | undefined;
}

const DataEmbed = ({ code, context, unit }: DataEmbedProps) => {
  const gestureData = getGestureData(code);
  if (!gestureData) {
    return null;
  }
  const gestures = gestureData.data;
  if (!gestures) {
    return null;
  }

  const downloadUrl = codeDownloadUrl(
    unit,
    context as SanityMake | SanityLesson,
    code,
    "makecode",
    "hex"
  );
  return (
    <div className={styles.root}>
      <div className={styles.dataWrapper}>
        <div className={styles.dataContainer}>
          {(gestures as GestureData[]).map((gesture, i) => (
            <div className={styles.row} key={i}>
              <GestureName gestureName={gesture.name} icon={gesture.icon} />
              <Recordings recordings={gesture.recordings} />
            </div>
          ))}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Row align="center" valign="middle">
          <Col>
            <OpenInCreateAIButton
              context={context}
              code={code}
              editor={"makecode"}
            />
          </Col>
          <Col>
            <AnchorButton
              to={downloadUrl}
              analyticsDownloadId={downloadUrl}
              className={styles.button}
            >
              <Text variant="button">
                <GetText id={"download-hex"} />
              </Text>
            </AnchorButton>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const GestureName = ({
  gestureName,
  icon
}: {
  gestureName: string;
  icon: Icon;
}) => {
  return (
    <div className={classNames(styles.nameContainer, styles.card)}>
      <LedIcon icon={icon} />
      <div className={styles.name}>
        <Text variant="default">{gestureName}</Text>
      </div>
    </div>
  );
};

const LedIcon = ({ icon }: { icon: Icon }) => {
  const iconData = icons[icon];
  return (
    <div className={styles.icon}>
      {Array.from(Array(5)).map((_, i) => {
        const start = i * 5;
        return (
          <LedIconRow data={iconData.substring(start, start + 5)} key={i} />
        );
      })}
    </div>
  );
};

const LedIconRow = ({ data }: { data: string }) => {
  return (
    <div className={styles.iconRow}>
      {Array.from(Array(5)).map((_, i) => (
        <div
          className={classNames(styles.led, data[i] === "1" && styles.on)}
          key={i}
        />
      ))}
    </div>
  );
};

const Recordings = ({ recordings }: { recordings: RecordingData[] }) => {
  return (
    <div className={classNames(styles.recordings, styles.card)}>
      {recordings.map((recording, i) => (
        <Recording key={i} recording={recording} />
      ))}
    </div>
  );
};

const Recording = ({ recording }: { recording: RecordingData }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const colors = useGraphColors();
  useEffect(() => {
    Chart.unregister(...registerables);
    Chart.register([LinearScale, LineController, PointElement, LineElement]);
    const chart = new Chart(
      canvasRef.current?.getContext("2d") ?? new HTMLCanvasElement(),
      getRecordingGraphConfig(recording.data, colors)
    );
    return () => {
      chart.destroy();
    };
  }, [colors, recording]);

  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <div className={styles.card} ref={containerRef}>
      <canvas width="158px" height="95px" ref={canvasRef} />
    </div>
  );
};

const useGraphColors = () => {
  const x = "rgb(255, 68, 68)";
  const y = "rgb(29, 157, 29)";
  const z = "rgb(0, 0, 191)";
  return { x, y, z };
};

export default DataEmbed;
