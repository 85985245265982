import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import BlockContentOutline from "../components/BlockContentOutline/BlockContentOutline";
import Breadcrumbs, { Breadcrumb } from "../components/Breadcrumbs/Breadcrumbs";
import Card from "../components/Card/Card";
import CreditedContent from "../components/CreditedContent/CreditedContent";
import DataEmbed from "../components/DataEmbed/DataEmbed";
import EditorEmbed from "../components/EditorEmbed/EditorEmbed";
import EditorEmbedSupport from "../components/EditorEmbedSupport/EditorEmbedSupport";
import { MakeTagInfo } from "../components/EducationTagInfo/EducationTagInfo";
import FavouriteButton from "../components/FavouriteButton/FavouriteButton";
import RelatedMakeContent from "../components/RelatedMakeContent/RelatedMakeContent";
import GetText from "../i18n/GetText";
import ContentLayout from "../layouts/ContentLayout/ContentLayout";
import PageLayout from "../layouts/PageLayout/PageLayout";
import {
  CommonContext,
  MicrobitCode,
  SanityCampaign,
  SanityPage
} from "../model/common";
import { SanityMake } from "../model/makes";
import { GlobalMenus } from "../model/menu";
import { urlForDocument } from "../urls";
import { SiteArea } from "../utils/analytics";
import styles from "./MakePage.module.scss";

export const pageQuery = graphql`
  query MakePage($_id: String!, $language: String, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    listings: sanityMakeListingPage(language: { eq: $language }) {
      _id
      _type
      title
    }
    make: sanityMake(_id: { eq: $_id }) {
      _id
      _type
      language
      title
      fields {
        editorLinks
      }
      slug {
        current
      }
      topics {
        _id
        name
      }
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      level
      programmingLanguages
      projectCode {
        _key
        name
        editors
        scratch {
          url
          file {
            asset {
              originalFilename
              extension
              path
            }
          }
          scratchImages {
            asset {
              ...SanityImageAsset
            }
          }
        }
        makecode {
          project
          url
        }
        python {
          main
        }
      }
      _rawContent(resolveReferences: { maxDepth: 7 })
      deviceFeatures {
        _id
        name
      }
      related {
        _id
        _type
        language
        description
        level
        title
        image {
          ...SanityImage
        }
        slug {
          current
        }
      }
      translationSponsors {
        ...TranslationSponsors
      }
      licence {
        ...Licence
      }
    }
    campaigns: allSanityCampaign(
      filter: { activeSiteAreas: { in: "projects" } }
    ) {
      nodes {
        ...Campaign
      }
    }
  }
`;

const MakePage = ({
  pageContext,
  data: { menus, make, listings, campaigns },
  location
}: MakePageProps) => {
  const {
    title,
    _rawContent,
    projectCode,
    related,
    licence,
    translationSponsors,
    _id
  } = make;
  const breadcrumbs: Breadcrumb[] = [
    {
      href: urlForDocument(listings),
      title: listings.title
    },
    {
      title,
      href: urlForDocument(make)
    }
  ];

  const customEmbeds = {
    microbitCode: ({ node }: { node: MicrobitCode }) => (
      <EditorEmbed unit={undefined} code={node} context={make} />
    ),
    microbitCodeEmbed: ({ node }: { node: { microbitCodeRef: string } }) => {
      const code = projectCode.find(v => v._key === node.microbitCodeRef);
      if (!code) {
        return null;
      }
      return <EditorEmbed unit={undefined} code={code} context={make} />;
    },
    microbitCreateAiDataEmbed: ({
      node
    }: {
      node: { microbitCodeRef: string };
    }) => {
      const code = projectCode.find(v => v._key === node.microbitCodeRef);
      if (!code) {
        return null;
      }
      return <DataEmbed unit={undefined} code={code} context={make} />;
    }
  };

  return (
    <EditorEmbedSupport document={make}>
      <PageLayout
        siteArea={SiteArea.MAKE_IT_CODE_IT}
        metadata={{
          title,
          alternates: pageContext.alternates,
          page: make
        }}
        menus={menus}
        strings={pageContext.strings}
        location={location}
      >
        <ContentLayout
          campaigns={campaigns.nodes}
          title={title}
          tags={<MakeTagInfo value={make} />}
          type={<GetText id="activity" />}
          breadcrumbs={<Breadcrumbs breadcrumbs={breadcrumbs} />}
          aside={
            <nav>
              <BlockContentOutline showOnMobile content={_rawContent} />
            </nav>
          }
          content={
            <Card type="main" className={styles.favouriteButtonContainer}>
              <CreditedContent
                content={_rawContent}
                customEmbeds={customEmbeds}
                cap="3/4"
                licence={licence}
                translationSponsors={translationSponsors}
              >
                {!!related.length && (
                  <RelatedMakeContent
                    content={related}
                    className={styles.related}
                  />
                )}
                <FavouriteButton className={styles.favouriteButton} id={_id} />
              </CreditedContent>
            </Card>
          }
        />
      </PageLayout>
    </EditorEmbedSupport>
  );
};

export default MakePage;

interface MakePageProps {
  data: {
    menus: GlobalMenus;
    listings: SanityPage;
    make: SanityMake;
    campaigns: { nodes: SanityCampaign[] };
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
